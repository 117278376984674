import React from "react";
import Soetta from "./Soetta";
import * as Icon from 'react-feather';
import Footer from "./Footer";
import About from "./About";
import AvailableOn from "./AvailableOn";
import FeaturedOn from "./FeaturedOn";
import Partners from "./Partners";
import Nav from "./Nav";
const Home = () => {
  return (
    <>
      <Nav />
      <Soetta />
      <About />
      <AvailableOn />
      <FeaturedOn />
      <Partners />
      <Footer />
    </>
  );
};

export default Home;