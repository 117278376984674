import React from "react";
import ImageGallery from 'react-image-gallery';
const Soetta = () => {
  const images = [
    { original: '/assets/images/Pilihan/slider/1.jpg', thumbnail: '/assets/images/Pilihan/slider/1.jpg' },
    { original: '/assets/images/Pilihan/slider/2.jpg', thumbnail: '/assets/images/Pilihan/slider/2.jpg' },
    { original: '/assets/images/Pilihan/slider/3.jpg', thumbnail: '/assets/images/Pilihan/slider/3.jpg' },
    { original: '/assets/images/Pilihan/slider/4.jpg', thumbnail: '/assets/images/Pilihan/slider/4.jpg' },
    { original: '/assets/images/Pilihan/slider/5.jpg', thumbnail: '/assets/images/Pilihan/slider/5.jpg' },
    { original: '/assets/images/Pilihan/slider/6.jpg', thumbnail: '/assets/images/Pilihan/slider/6.jpg' },
    { original: '/assets/images/Pilihan/slider/7.jpg', thumbnail: '/assets/images/Pilihan/slider/7.jpg' },
    { original: '/assets/images/Pilihan/slider/7_1.jpg', thumbnail: '/assets/images/Pilihan/slider/7_1.jpg' },
    { original: '/assets/images/Pilihan/slider/8.jpg', thumbnail: '/assets/images/Pilihan/slider/8.jpg' },
    { original: '/assets/images/Pilihan/slider/9.jpg', thumbnail: '/assets/images/Pilihan/slider/9.jpg' },
    { original: '/assets/images/Pilihan/slider/10.jpg', thumbnail: '/assets/images/Pilihan/slider/10.jpg' },
    { original: '/assets/images/Pilihan/scroller/1.jpg', thumbnail: '/assets/images/Pilihan/scroller/1.jpg' },
    { original: '/assets/images/Pilihan/scroller/3.jpg', thumbnail: '/assets/images/Pilihan/scroller/3.jpg' },
    { original: '/assets/images/Pilihan/scroller/4.jpg', thumbnail: '/assets/images/Pilihan/scroller/4.jpg' },
    { original: '/assets/images/Pilihan/scroller/4_1.jpg', thumbnail: '/assets/images/Pilihan/scroller/4_1.jpg' },
    { original: '/assets/images/Pilihan/scroller/5.jpg', thumbnail: '/assets/images/Pilihan/scroller/5.jpg' },
    { original: '/assets/images/Pilihan/scroller/6.jpg', thumbnail: '/assets/images/Pilihan/scroller/6.jpg' },
    { original: '/assets/images/Pilihan/scroller/6_1.jpg', thumbnail: '/assets/images/Pilihan/scroller/6_1.jpg' },
    { original: '/assets/images/Pilihan/scroller/7.jpg', thumbnail: '/assets/images/Pilihan/scroller/7.jpg' },
    { original: '/assets/images/Pilihan/scroller/7_1.jpg', thumbnail: '/assets/images/Pilihan/scroller/7_1.jpg' },
    { original: '/assets/images/Pilihan/scroller/2.jpg', thumbnail: '/assets/images/Pilihan/scroller/2.jpg' },
    { original: '/assets/images/Pilihan/scroller/4_2 (2).jpg', thumbnail: '/assets/images/Pilihan/scroller/4_2 (2).jpg' },
    { original: '/assets/images/Pilihan/scroller/4_3.jpg', thumbnail: '/assets/images/Pilihan/scroller/4_3.jpg' },
    { original: '/assets/images/Pilihan/slider/11.jpg', thumbnail: '/assets/images/Pilihan/slider/11.jpg' },
    { original: '/assets/images/Pilihan/slider/12.jpg', thumbnail: '/assets/images/Pilihan/slider/12.jpg' },
    { original: '/assets/images/Pilihan/slider/13.jpg', thumbnail: '/assets/images/Pilihan/slider/13.jpg' },
    { original: '/assets/images/Pilihan/slider/14.jpg', thumbnail: '/assets/images/Pilihan/slider/14.jpg' },
    { original: '/assets/images/Pilihan/slider/15.jpg', thumbnail: '/assets/images/Pilihan/slider/15.jpg' },
    { original: '/assets/images/Pilihan/scroller/8.jpg', thumbnail: '/assets/images/Pilihan/scroller/8.jpg' },
    { original: '/assets/images/Pilihan/scroller/9.jpg', thumbnail: '/assets/images/Pilihan/scroller/9.jpg' },
    { original: '/assets/images/Pilihan/scroller/20211022000702_1.jpg', thumbnail: '/assets/images/Pilihan/scroller/20211022000702_1.jpg' },
  ];

  return (
    <div className="container py-5">
      <div className="row mt-5">
        <div className="col-lg-6">
          <h1>Now Available:<br /> BinerSim - Soekarno-Hatta for MSFS2020</h1>
        </div>
        <div className="col-lg-6 mr-auto ml-auto">
          <ImageGallery items={images} showPlayButton={false} />
        </div>
        <div className="offset-lg-4 col-lg-4 col-12 mt-5 ">
          <div className="text-container" style={{ textAlign: "center" }}>
            <h6>Available exclusively on</h6>
            <div className="row">
              <div className="col-lg-12">
                <img src="https://upload.wikimedia.org/wikipedia/en/6/68/Microsoft_Flight_Simulator_logo_%282020%29.png" width="35%" />
                <p className="p-small">In-Game Marketplace</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <hr style={{ borderTop: "1px solid rgb(130 130 130)", width: "5%" }} />
        </div>
      </div>
    </div>
  );
};
export default Soetta;