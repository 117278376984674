import React from "react";
import * as Icon from 'react-feather';
const FeaturedOn = () => {
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-12">
          <h2 style={{ textAlign: "center" }}>Featured on</h2>
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <a href="https://www.goodnewsfromindonesia.id/2021/11/07/developer-indonesia-bawa-visual-bandara-soekarno-hatta-ke-peta-video-game-x-plane" target="_blank">
                <img className="w-100" src="https://www.goodnewsfromindonesia.id/img/gnfi-logo-1900.jpg" alt="alternative" style={{ borderRadius: "5%" }} />
              </a>
            </div>
            <div className="col-lg-2">
              <a href="https://fselite.net/?s=binersim" target="_blank">
                <img className="w-100" src="https://sfo3.digitaloceanspaces.com/fseimg/2021/06/logo_fse-1.svg" alt="alternative" style={{ borderRadius: "5%", background: "white", border: "19px solid white" }} />
              </a>
            </div>
            <div className="col-lg-2">
              <a href="https://www.thresholdx.net/news/bsimsh" target="_blank">
                <img className="w-100" src="/assets/images/threshold logo.jpg" alt="alternative" width="132px" style={{ borderRadius: "5%" }} />
              </a>
            </div>
            <div className="col-lg-2">
              <a href="https://www.simflight.com/?s=binersim" target="_blank">
                <img className="w-100" src="https://www.simflight.com/wp-content/uploads/2020/08/simflight_logo_315x90-NEW.jpg" alt="alternative" width="132px" style={{ borderRadius: "5%", background: "white", border: "19px solid white" }} />
              </a>
            </div>
            <div className="col-lg-2">
              <a href="https://fsnews.eu/?s=binersim" target="_blank">
                <img className="w-100" src="https://fsnews.eu/wp-content/uploads/2019/10/fsnews-logo.png" alt="alternative" width="132px" style={{ borderRadius: "5%", background: "white", border: "19px solid white" }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedOn;