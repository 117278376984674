import React from "react";
import * as Icon from 'react-feather';
const Nav = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <img src={`/assets/images/logo.png`} width="210px" className="mx-auto d-block" />
        </div>
      </div>
    </div>
  );
};

export default Nav;