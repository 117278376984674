import React from "react";
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const About = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-xl-5">
          <h1>Scenery development team for flight simulators.</h1>
          <p>The team was founded in 2013 in Indonesia.
            We develop scenery for Flight Simulator by creating 3D models of many airports and
            various objects realistically to enhance your experience on the simulator.</p>
        </div>
        <div className="col-lg-2"></div>
        <div className="col-lg-4">
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="binersim"
            options={{height: 350}}
            theme="light"
          />
        </div>
      </div>
    </div >
  );
};

export default About;
