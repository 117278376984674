import React from "react";
import * as Icon from 'react-feather';
const AvailableOn = () => {
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-container" style={{ textAlign: "center" }}>
            <h2>Available on</h2>
            <p>Get our products in these stores</p>
            <div className="row justify-content-center">
              <div className="col-lg-4">
                <a href="https://onesimstore.com/publisher/binersim/1" target="_blank">
                  <img src="https://onesimstore-public.b-cdn.net/assets/logo.png" className="w-50" />
                </a>
                <p>Freeware available</p>
              </div>
              <div className="col-lg-4">
                <a href="https://secure.simmarket.com/binersim-(en_1208).mhtml" target="_blank">
                  <img src="https://www.simflight.com/wp-content/uploads/2020/08/00_simmarket_logo.png" className="w-50" />
                </a>
              </div>
              <div className="col-lg-4">
                <img src="https://upload.wikimedia.org/wikipedia/en/6/68/Microsoft_Flight_Simulator_logo_%282020%29.png" className="w-50" />
                <p>In-Game Marketplace</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailableOn;