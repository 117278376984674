import React from "react";
import * as Icon from 'react-feather';

// Array of social media platforms
const socialMediaPlatforms = [
  
  { name: 'Instagram', url: 'https://instagram.com/binersim_id', icon: Icon.Instagram },
  { name: 'Twitter', url: 'https://twitter.com/binersim', icon: Icon.Twitter },
  { name: 'Facebook', url: 'https://facebook.com/binersim', icon: Icon.Facebook },
  { name: 'Youtube', url: 'https://youtube.com/@binersim', icon: Icon.Youtube },
  { name: 'Linkedin', url: 'https://linkedin.com/company/binersim', icon: Icon.Linkedin },
  // { name: 'Discord', url: 'https://discord.binersim.com/', icon: Icon.Discord } // replace with actual Discord icon
];
const Footer = () => {
  return (
    <div className="footer container-fluid bg-black text-white p-5">
      <div className="row">
        <div className="col-md-4">
          <p style={{ fontWeight: "bold" }}>BinerSim</p>
          <img src={`/assets/images/Bangga_Buatan_Indonesia_Tipe_Normal.png`} width="10%" />
        </div>

        <div className="col-md-4">
          <p className="text-white" style={{ fontWeight: "bold" }}>Contact</p>
          <ul className="list-unstyled li-space-lg p-small">
            <li className="media">
              <i className="fas fa-envelope"></i>
              <div>
                <a className="text-white" href="mailto:admin@binersim.com">admin@binersim.com</a>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-md-4">
          <p style={{ fontWeight: "bold" }}>Social Media</p>
          <ul className="list-unstyled li-space-lg p-small">
            <li>
              {socialMediaPlatforms.map(platform => (
                <a key={platform.name} className="social-icon pe-2" href={platform.url} target="_blank">
                  {typeof platform.icon === 'string' ? <i className={platform.icon} style={{ color: 'white' }} /> : <platform.icon style={{ color: 'white' }} />}
                </a>
              ))}
            </li>
          </ul>
        </div>
      </div>
    </div >
  );
};

export default Footer;