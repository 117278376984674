import React from "react";
import * as Icon from 'react-feather';
const Partners = () => {
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-12">
          <h2 style={{ textAlign: "center" }}>Who uses our products?</h2>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-2">
            <div>
              <a href="https://www.traveloka.com/id-id/activities/indonesia/product/flying-package-at-aircrew-sensation-2000149438651">
                <img className="w-100"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5Asqnr8LFeUqh_kRHnG9qFZ_D1QchIM0G6g&usqp=CAU"
                  alt="alternative" style={{ borderRadius: "5%" }} />
              </a>
            </div>
          </div>
          <div className="col-md-2 ">
            <a href="http://airspeedindonesia.com/">
              <img className="w-100"
                src="/assets/images/AirSpeedIndonesia.jpg"
                alt="alternative" style={{ borderRadius: "5%" }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;